// store
// import { sign } from "core-js/core/number";
import store from "./store";

String.prototype.replaceArray = function (find, replace) {
  let replaceString = this;
  for (let i = 0; i < find.length; i++) {
    replaceString = replaceString.replaceAll(find[i], replace[i]);
  }
  return replaceString;
};

String.prototype.turkishToUpper = function () {
  var string = this;
  return string.toLocaleUpperCase("tr-TR");
};
String.prototype.turkishToLower = function () {
  var string = this;
  return string.toLocaleLowerCase("tr-TR");
};
String.prototype.turkishToTitle = function () {
  var string = this;
  return string
    .toLocaleLowerCase("tr-TR")
    .replace(/(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g, function (match) {
      return match.toLocaleUpperCase("tr-TR");
    });
};
String.prototype.turkishToUpperAndJoin = function (joinParam = "_") {
  var string = this;
  const letters = {
    İ: "i",
    I: "i",
    Ş: "s",
    Ğ: "g",
    Ü: "u",
    Ö: "o",
    Ç: "c",
    ı: "i",
    ş: "s",
    ğ: "g",
    ü: "u",
    ç: "c",
    ö: "o",
  };
  string = string
    .replace(/(([İIŞĞÜÇÖışğüçö]))/g, (letter) => letters[letter])
    .replace(/\s/g, joinParam);
  return string.toUpperCase();
};

function MonthLong(locate = "tr") {
  if (locate === "tr")
    return [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
  else if (locate === "en")
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  else
    return [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
}

function MonthShort(locate = "tr") {
  if (locate === "tr")
    return [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ];
  else if (locate === "en")
    return [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  else
    return [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ];
}

function DaysLong(locate = "tr") {
  if (locate === "tr")
    return [
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
      "Pazar",
    ];
  else if (locate === "en")
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
  else
    return [
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
      "Pazar",
    ];
}

function DaysShort(locate = "tr") {
  if (locate === "tr") return ["Pzt", "Sal", "Çar", "Per", "Cum", "Cmt", "Paz"];
  else if (locate === "en")
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  else return ["Pzt", "Sal", "Çar", "Per", "Cum", "Cmt", "Paz"];
}

function dateTimeFormatter(
  dateTime,
  locate = "tr",
  format = "{dd}.{mm}.{YYYY} {HH}:{ii}:{ss}"
) {
  const date = new Date(dateTime);

  const arrMonthLong = MonthLong(locate);
  const arrMonthShort = MonthShort(locate);
  const arrDaysLong = DaysLong(locate);
  const arrDaysShort = DaysShort(locate);

  const day = date.getDate().toString();
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();

  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const result = format.replaceArray(
    [
      // GÜNLER
      "{d}",
      "{dd}",
      "{ddd}",
      "{dddd}",
      "{w}",
      // AYLAR
      "{m}",
      "{mm}",
      "{mmm}",
      "{mmmm}",
      // YILLAR
      "{yyyy}",
      "{yy}",
      // MERIDIEM SAAT FORMATI
      "{a}",
      "{A}",
      // SAATLER
      "{h}",
      "{hh}",
      "{H}",
      "{HH}",
      // DAKİKALAR
      "{i}",
      "{ii}",
      // SANİYELER
      "{s}",
      "{ss}",
    ],
    [
      // GÜNLER
      day, // d - Başında sıfır olmadan ayın günü
      day[1] ? day : "0" + day[0], // dd - Başında sıfır olan ayın günü 2 haneli sayısal gösterimi
      arrDaysShort[date.getDay()], // ddd - Haftanın gününün kısaltılmış metinsel temsili, üç harf
      arrDaysLong[date.getDay()], // dddd - Haftanın gününün tam metinsel temsili
      date.getDay(), // w - Haftanın gününün sayısal gösterimi

      // AYLAR
      month, // m - Başında sıfır olmadan ayın sayısal gösterimi
      month[1] ? month : "0" + month[0], // mm - Başında sıfır olan ayın 2 haneli sayısal gösterimi
      arrMonthShort[date.getMonth()], // mmm - Bir ayın kısaltılmış metinsel gösterimi, üç harf
      arrMonthLong[date.getMonth()], // mmmm - Ayın tam metinsel temsili

      // YILLAR
      year, // yyyy - Bir yılın 4 basamaklı tam sayısal temsili
      parseInt(year.substr(2, 2)), // yy - Bir yılın iki basamaklı gösterimi

      // MERIDIEM SAAT FORMATI
      hour > 12 ? "am" : "pm", // a - Küçük Ante meridiem ve Post meridiem
      hour > 12 ? "AM" : "PM", // A - Büyük Ante meridiem ve Post meridiem

      // SAATLER
      hour % 12, // h - Başında sıfır olmadan bir saatin 12 saatlik biçimi
      hour, // hh - Başında sıfır olmadan bir saatin 24 saatlik biçimi
      (hour % 12).toString()[1]
        ? (hour % 12).toString()
        : "0" + (hour % 12).toString()[0], // H - Başında sıfırlar bulunan bir saatin 12 saatlik biçimi
      hour.toString()[1] ? hour.toString() : "0" + hour.toString()[0], // HH - Başında sıfırlar bulunan bir saatin 24 saatlik biçimi
      // DAKİKALAR
      minutes, // i - Başında sıfır olmadan dakika biçimi
      minutes.toString()[1] ? minutes.toString() : "0" + minutes.toString()[0], // ii -Başında sıfırlar bulunan dakika biçimi
      // SANİYELER
      seconds, // s - Başında sıfır olmadan  saniye biçimi
      seconds.toString()[1] ? seconds.toString() : "0" + seconds.toString()[0], // ss - Başında sıfırlar bulunan saniye biçimi
    ]
  );

  return result;
}

function slugify(text, ampersand = "and") {
  const a = "àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧığÇşŞÜü";
  const b = "aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzhigcssuu";
  const p = new RegExp(a.split("").join("|"), "g");

  return text
    .toString()
    .toLowerCase()
    .replace(/[\s_]+/g, "-")
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/&/g, `-${ampersand}-`)
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+|-+$/g, "");
}

function strOnlyFirstCharUpperCase(str) {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function strInitialsUpperCase(str) {
  if (typeof str !== "string") return "";
  return str.split(" ").map(strOnlyFirstCharUpperCase).join(" ");
}

// Hangi formatta telefon numarası verilirse verilsin çıktıyı;
// Eğer başında + ülke kodu var ise => +xx (xxx) xxx xxxx formatında, Örneğin: +90 (216) 347 9634
// Eğer başında + ülke kodu yoksa => (xxxx) xxx xxxx formatında, Örneğin: (0216) 347 9634
// verir..
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/[^0-9+]/g, "");
  var match = cleaned.match(
    /^(\+[0-9]\d{0,9})?(\d{3,4})(\d{3})(\d{2})(\d{2})$/
  );

  if (match)
    return [
      match[1] ? match[1] + " " : "",
      "(",
      match[2].charAt(0) === "0"
        ? match[2]
        : match[1]
        ? match[2]
        : "0" + match[2],
      ") ",
      match[3],
      " ",
      match[4],
      match[5],
    ].join("");
  else return phoneNumberString;
}

function submitValidController(arrFormErrors = [], arrShakes = [], callback) {
  var arrTimeoutKeys = [];
  // Bir döngüyle Form içindeki alanlarda hata var ise shake yapıyoruz.
  for (var key in arrFormErrors) {
    if (arrFormErrors[key] !== "") {
      arrShakes[key] = true;

      arrTimeoutKeys.push({
        key: key,
        timeout: setTimeout(() => {
          arrShakes[arrTimeoutKeys[0].key] = false;
          clearTimeout(arrTimeoutKeys[0].timeout);
          arrTimeoutKeys.splice(0, 1);
        }, 1000),
      });
    }
  }

  // Form içinde eğer hata var ise return ile işlemi durduruyoruz.
  for (var key in arrFormErrors) {
    if (arrFormErrors[key] !== "") {
      callback(false);
      return;
    }
  }

  callback(true);
}

function toMoneySelectedCurrency(currency, money) {
  return parseFloat(
    currency.currencyCode === "TRY"
      ? money
      : (money / currency.banknoteSelling).toFixed(2)
  );
}

function toMoneyFormat(money, willItBeManipulated) {
  if (willItBeManipulated) {
    var generalPriceRate = store.state.generalPriceRate;
    if (generalPriceRate) money = money * (1 + generalPriceRate / 100);
  }

  return money;

  var currency_symbol = "₺";
  var formattedOutput = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 0,
  });

  return formattedOutput.format(money).replace(currency_symbol, "") || "0";
}

// Sleep fonksiyonu. await sleep(1000) şeklinde kullanın
function sleep(ms) {
  return new Promise((resolver) => setTimeout(resolver, ms));
}

function randomStr(len, arr = "0123456789acbdefghijklmnoprstuvyzqxw") {
  var result = "";

  for (var i = len; i > 0; i--) {
    result += arr[Math.floor(Math.random() * arr.length)];
  }

  return result;
}

// Input'un hata durumunda sallanma efektini tetikleyen fonksiyon
function shaker(obj) {
  Object.keys(obj.fields).forEach((e) => {
    // Gönderilen obj.form objesini Object.keys ile array'e çevirip key'leri döndürüyoruz.
    if (typeof obj.fields[e].valid === "object" && obj.fields[e].valid) {
      // e değeri bir obje ise ve aynı zamanda fields objesinde de yer alıyorsa
      if (
        obj.fields[e].valid.value === false ||
        obj.fields[e].valid.value === null
      ) {
        // e objesindeki valid false ise
        obj.fields[e].options.shake = true; // e'nin fields'daki options.shake değerini true yapıyoruz. (Sallanma efekti başlar)

        setTimeout(() => {
          // 1 saniyelik sallanma sonrasında
          obj.fields[e].options.shake = false; // e'nin fields'daki options.shake değerini false yapıyoruz. (Sallanma efekti durur)
        }, 1000);
      }
    }
  });
}

function TCKontrol(tcKimlikNo) {
  var tek = 0,
    cift = 0,
    sonuc = 0,
    TCToplam = 0,
    i = 0,
    hatali = [
      11111111110, 22222222220, 33333333330, 44444444440, 55555555550,
      66666666660, 7777777770, 88888888880, 99999999990,
    ];

  if (tcKimlikNo.length != 11) return false;
  if (isNaN(tcKimlikNo)) return false;
  if (tcKimlikNo[0] == 0) return false;

  tek =
    parseInt(tcKimlikNo[0]) +
    parseInt(tcKimlikNo[2]) +
    parseInt(tcKimlikNo[4]) +
    parseInt(tcKimlikNo[6]) +
    parseInt(tcKimlikNo[8]);
  cift =
    parseInt(tcKimlikNo[1]) +
    parseInt(tcKimlikNo[3]) +
    parseInt(tcKimlikNo[5]) +
    parseInt(tcKimlikNo[7]);

  tek = tek * 7;
  sonuc = Math.abs(tek - cift);
  if (sonuc % 10 != tcKimlikNo[9]) return false;

  for (var i = 0; i < 10; i++) {
    TCToplam += parseInt(tcKimlikNo[i]);
  }

  if (TCToplam % 10 != tcKimlikNo[10]) return false;

  if (hatali.toString().indexOf(tcKimlikNo) != -1) return false;

  return true;
}

function onlyNumberKey(evt) {
  // Only ASCII character in that range allowed
  var ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
  return true;
}
const controllerRole = (stat, signularRules) => {
  return Array.isArray(stat)
    ? signularRules.map((s) => stat.includes(s) && true).includes(true)
    : signularRules.includes(stat);
};
export default {
  controllerRole,
  MonthLong,
  MonthShort,
  DaysLong,
  DaysShort,
  dateTimeFormatter,
  slugify,
  strOnlyFirstCharUpperCase,
  strInitialsUpperCase,
  formatPhoneNumber,
  submitValidController,
  toMoneySelectedCurrency,
  toMoneyFormat,
  sleep,
  randomStr,
  shaker,
  TCKontrol,
  onlyNumberKey,
};
